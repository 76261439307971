footer{
    position: absolute;
    width: 100%;
    // box-shadow: inset 0px 0px 400px 110px rgba(0, 0, 0, .9);
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    .footer-content{
        margin: 50px 0px;
        .block-1{
            
        }   
        .block-2{
            h4{
                color:#fff;
            }
            ul{
                padding:0px;
                margin-top: 30px;
                li{
                    list-style-type: none;
                    padding: 8px 0px;
                    a{
                        text-decoration: none;
                        color: #fff;
                        // font-weight: 500;
                        &:hover{
                            color: #c29f15;
                        }
                    }
                }
            }
        }
        .block-3{
            ul{
                padding:0px;
                margin-top:60px;
                li{
                    
                    list-style-type: none;
                    padding: 8px 0px;
                    a{
                        text-decoration: none;
                        color: #fff;
                        // font-weight: 500;
                        &:hover{
                            color: #c29f15;
                        }

                    }
                }
            }
        }
        .block-4{
            h4{
                color:#fff;
                margin-bottom: 30px;
            }
            .message{
                margin-top: 22px;
            }
            .mobile, 
            .message, 
            .location{
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                .icon{
                    width: 15%;
                    text-align: center;
                    background: #c29f15;
                    padding: 10px;
                    margin-right: 20px;
                    border-radius: 50%;
                    svg{
                        // padding: 20px;
                        // background-color: red;
                    }
                }
                .label{
                    width: 85%;
                    color:#fff;
                }
            }
        }
    }
}