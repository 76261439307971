section.careers{
    .banner{
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .content{
        margin: 100px 0px;
        .heading{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;
                // margin: 50px 0px;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
            
        }
        .content-details{
            margin: 20px 0px;
        }
    }   
}