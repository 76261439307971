section.truck-common{
    .banner{
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .content{
        margin: 100px 0px;
        .heading{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;
                // margin: 50px 0px;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
            
        }
        .points-wrapper{
            ul{
                li{
                    list-style-type: square;
                    padding: 10px;
                    font-size: 24px;
                    font-weight: 300;
                }
            }
        }

        .contact-form-wrapper{
            padding: 3rem;
            // max-width: 450px;
            animation: unset;
            min-height: unset;
            
        }

        .truck-img{

        }

        .truck-details{
            padding:40px;
            
        }

        .services{
            display: flex;
            flex-direction: column;
            align-items: center;
            ul{
                display: flex;
                justify-content: center;
                width: 75%;
                flex-wrap: wrap;
                
                li{
                    background-color: #fff;
                    padding: 5px;
                    list-style-type: none;
                    margin: 15px;
                    border-radius: 10px;
                    padding: 12px 30px;
                    
                    box-shadow: -1px 1px 6px -2px rgba(0,0,0,0.51);
                    -webkit-box-shadow: -1px 1px 6px -2px rgba(0,0,0,0.51);
                    -moz-box-shadow: -1px 1px 6px -2px rgba(0,0,0,0.51);
                    &:hover{
                        font-size: 18px;
                        transition: all 50ms !important;

                    }
                    a{
                        text-decoration: none;
                        font-weight: bold;
                        
                    }
                }
                
            }
        }

        .contact-links{
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
            justify-content: center;
            // margin-bottom: 30px;
            border-radius: 0;
            font-weight: 700;
            font-size: 20px;
            
            a{
                background-color: #fff;
                text-decoration: none;
                padding: 10px 70px;
                margin-right: 20px;
                color: #000;
                &:first-child{
                    border-color: #45a4e2;
                    background-color: #45a4e2;
                    color: #fff;
                    &:hover{
                        background-color: #1a6292;
                    }
                }
            }
        }
    }   
}