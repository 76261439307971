.faq{
    .faq-banner{
        img{
            width: 100%;
            object-fit: cover;
        }
    }

    .faq-content{
        margin: 100px 0px;
        .faq-heading{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;
                // margin: 50px 0px;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
        }
        .content-info{
            h2{
                font-size: 2rem;
                line-height: 1.2;
                font-weight: bold;
            }
            p{
                margin-bottom: 2rem;
                font-weight: 400;
                font-size: 20px;
                line-height: 1.2;
            }
        }
        .questions{
            padding:10px 0px;
        }
    }
}