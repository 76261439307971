.scroll-up-arrow{
    background-color: red;
    color: white;
    display: inline-block;
    padding: 14px 20px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    position: fixed;
    right: 60px;
    bottom: 60px;
}
