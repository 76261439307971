.truck-types{
    .content-wrapper{
        margin: 50px 0px;
        .truck-types-heading{
            margin-top: 10%;
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
        }
        .img{

        }
        .details{
            // position: relative;
            padding: 40px;
            &::after{
                // position: absolute;
                // content: "";
                // height: 50px;
                // width: 10px;
                // background: red;
                // left: 0;
                // top:0;
            }
            h4{
                font-size: 36px;
                font-weight: bold;
                margin-bottom: 30px;
            }
            p{
                font-size: 18px;
                line-height: 1.3;
            }
            a{
                text-decoration: none;
                font-size: 20px;
                font-weight: 600;
                color: #1a91dd;
                &:hover{
                    font-size: 22px;
                }
            }
        }
    }
}