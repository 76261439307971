.pr-0{padding-right:0px !important}
.pl-0{padding-left:0px !important}
.mr-0{padding-right:0px !important}
.ml-0{padding-left:0px !important}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #eef0f3 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:hover{
  transition: all 500ms;
}

.border-red{
  border: 1px solid #f05656 !important;
}

.text-red{
  color:#f05656;
  font-weight: 600;
  font-size: 14px;
}
