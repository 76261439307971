@keyframes slidein-right {
    from {
      margin-left: 100%;
      width: 100%;
    }
    to {
      margin-left: 0%;
      width: 100%;
    }
}
.contact-form-wrapper{
    padding: 4rem;
    background-color: #fff;
    min-height:400px;
    animation-duration: 1s;
    animation-name: slidein-right;
    min-height: 700px;
    box-shadow: -1px 1px 11px -2px rgba(0,0,0,0.51);
    -webkit-box-shadow: -1px 1px 11px -2px rgba(0,0,0,0.51);
    -moz-box-shadow: -1px 1px 11px -2px rgba(0,0,0,0.51);
    border-radius: 15px;
    h5{
        margin-bottom: 1rem;
        font-size: 30px;
        line-height: 1.2;
        font-weight: 700;
    }
    form{
        // label{font-weight: 600}
        textarea,
        input[name=name], 
        input[name=phone], 
        input[name=email]{
            border-radius: 0;
            background-color: #eef0f3 !important;
            color: #000;
            font-weight: 400;
            font-size: 16px;
            padding: 15px;
            &:focus{
                box-shadow: unset;
                border-color: #000;
                border-left: 3px solid #000;
            }
        }

        .term-and-condition{
            text-decoration: none;
            color:#1a91dd;
        }

        input[name=terms], 
        input[name=tips]{
            
            &:focus{
                box-shadow: unset;
            }
        }
        .form-check-input:checked {
            border-color: #1a91dd;
            background-color: #1a91dd;
        }
        button[type=submit]{
            border-color: #1a91dd;
            background-color: #1a91dd;
            color: #fff;
            padding: 1rem 3rem;
            cursor: pointer;
            border-radius: 0;
            font-weight: 700;
            font-size: 20px;
            width: 100%;
            &:hover {
                border-color: #1776b1;
                background-color: #1776b1;
            }
        }
    }
}
