section.services-common{
    .banner{
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .content{
        margin: 100px 0px;
        .heading{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;
                // margin: 50px 0px;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
            
        }
        .points-wrapper{
            ul{
                li{
                    list-style-type: square;
                    padding: 10px;
                    font-size: 24px;
                    font-weight: 300;
                    
                }
            }
        }

        .contact-form-wrapper{
            padding: 3rem;
            // max-width: 450px;
            animation: unset;
            min-height: unset;
            
        }

        .img{
            width: 100%;
        }

        .faq-content{
            margin: 0px;
            .faq-headung{
                h4{
                    font-weight: bold;
                }
            }
        }

        .accordion .accordion-item .accordion-header button{
            font-size: 18px;
        }

        .accordion .accordion-item .accordion-body p {
            font-size: 16px;
        }

        .who-can-work {
            margin: 50px 0px;
        }

        .truck-details{
            padding:40px;
            
        }

        .services{
            
            h4{
                font-weight: bold;
                text-align: center;

            }
            ul{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                padding: 0px;
                
                li{
                    background-color: #1a91dd;
                    padding: 5px;
                    list-style-type: none;
                    margin: 5px;
                    border-radius: 5px;
                    padding: 12px 30px;
                    width: 23%;
                    text-align: center;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        font-weight: bold;
                        color:#fff;
                        font-size: 18px;
                        
                    }
                }
                
            }
        }

        .contact-links{
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
            justify-content: center;
            // margin-bottom: 30px;
            border-radius: 0;
            font-weight: 700;
            font-size: 20px;
            
            a{
                background-color: #fff;
                text-decoration: none;
                padding: 10px 70px;
                margin-right: 20px;
                color: #000;
                &:first-child{
                    border-color: #45a4e2;
                    background-color: #45a4e2;
                    color: #fff;
                    &:hover{
                        background-color: #1a6292;
                    }
                }
            }
        }
    }   
}