.services {
    .content-wrapper {
        margin: 50px 0px;

        .heading {
            margin-top: 10%;

            h1 {
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;

                &::before {
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
        }

        .details {
            padding: 40px;

            h4 {
                font-size: 36px;
                font-weight: bold;
                margin-bottom: 30px;
            }

            p {
                font-size: 18px;
                line-height: 1.3;
            }

            a {
                text-decoration: none;
                font-size: 20px;
                font-weight: 600;
                color: #1a91dd;

                &:hover {
                    font-size: 22px;
                }
            }
        }

        .gross-wrapper {
            background: #fff;
            padding: 70px 80px;
            margin: 50px 0px;

            .heading {
                margin-top: 0px;
                display: flex;
                justify-content: space-around;
                margin-bottom: 30px;

                h3 {
                    font-size: 35px;
                    font-weight: bold;
                }

                a {
                    border-color: #45a4e2;
                    background-color: #45a4e2;
                    color: #fff;
                    text-decoration: none;
                    padding: 10px 70px;
                    margin-right: 20px;
                    font-weight: 700;
                    font-size: 20px;

                    &:hover {
                        background-color: #1a6292;
                    }
                }
            }

            .points {
                ul {
                    li {
                        list-style-type: auto;
                        font-size: 19px;
                        font-weight: 600;
                    }
                }
            }
        }
        .contact-wrapper{
            background: #fff;
            margin: 50px 0px;
            padding: 50px 0px;
            .heading {
                margin-top: 0px;
                display: flex;
                justify-content: space-around;

                h3 {
                    font-size: 35px;
                    font-weight: bold;
                    margin:0px;
                }

                a {
                    border-color: #45a4e2;
                    background-color: #45a4e2;
                    color: #fff;
                    text-decoration: none;
                    padding: 10px 25px;
                    margin-right: 20px;
                    font-weight: 700;
                    font-size: 20px;

                    &:hover {
                        background-color: #1a6292;
                    }
                }
            }
        }   
    }
}