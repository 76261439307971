.referral-program{
    .banner{
        margin-top: 76px;
        img{
            width: 100%;
            object-fit: cover;
        }
    }

    .referral-content{
        background: #1a91dd;
        height: 100%;
        .title{
            text-align: center;
            color: #fff;
            h1{
                font-weight: bold;
                font-size: 3rem;
                padding: 25px 0px 10px 0px;
            }
            h2{
                font-weight: 400;
                font-size: 24px;
            }
        }
        .img{
            position: relative;
            margin-top:100px;
            text-align: center;
            img{
                width: 75%;
            }
            h3{
                background-color: bisque;
                display: inline-block;
                padding: 20px;
                font-weight: bold;
                border-radius: 50%;
                position: absolute;
                left: 60px;
                top: -51px;
                transform: rotate(-30deg);
            }
            h6{
                background-color: #ff0000;
                display: inline-block;
                padding: 12px;
                font-weight: bold;
                position: absolute;
                left: 434px;
                top: -30px;
                color: #fff;
                // transform: rotate(-1deg);
                border-radius: 50%;
            }
            h5{
                background-color: #2c76a6;
                display: inline-block;
                padding: 18px 10px;
                font-weight: bold;
                position: absolute;
                left: 214px;
                top: 20px;
                color: #fff;
                transform: rotate(10deg);
                font-size: 30px;
            }
            .logo{
                position: absolute;
                bottom: 40px;
                right: 186px;
                transform: rotate(-7deg);
                border-radius: 72px;
                display: inline-block;
                // background-color: #000;
                padding: 20px;
                img{
                    width: 90%;
                    
                }
            }
        }
    }
    .refer-block{
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 5px 15px;
        margin-bottom: 30px;
        .count{
            margin:10px;
            span{
                background: red;
                padding: 15px 25px;
                font-size: 30px;
                color: #fff;
                font-weight: bold;
            }
        }
        .details{
            margin:10px;
            h2{

            }
            p{
                margin-bottom: 0;
            }
        }
    }
}