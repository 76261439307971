.who-can-work{
    margin: 100px 0px;
    background: #000;
    padding: 110px 50px;
    .content-wrapper{
        min-height: 650px;
        display: flex;
        align-items: center;
        .started-heading-wrapper{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                color: #fff;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    /* left: 7px; */
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
        }
        .truck-types-wrapper{
            .truck-block{
                overflow: hidden;
                margin-bottom: 15px;
                .type-link{
                    position: relative;
                    display: block;
                    height: 250px;
                    // width: 350px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    cursor: pointer;
                    &:hover{
                        h4{
                            transition: all 500ms;
                            background: #c29f15;
                        }
                    }
                    h4{
                        position: absolute;
                        left: -21px;
                        bottom:20px;
                        text-decoration: none;
                        color:#fff;
                        font-weight: bold;
                        width: 264px;
                        padding: 10px 25px 15px 10px;
                        text-align: center;
                        
                        
                    }
                }
            }
        }
    }
}