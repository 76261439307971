section.testimonial{
    .banner{
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    .content{
        margin: 100px 0px;
        .heading{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;
                // margin: 50px 0px;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
            
        }
        .testimonial-card{
            background: #000;
            color: white;
            margin: 15px 0px;
            
            .card-img{
                height: 320px;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: fill;
                    position: center;
                }
            }
            .card-content{
                padding: 20px;
                p{
                    font-size: 14px;
                }
                p.truck-mc{
                    color:#1a91dd;
                    font-weight: 700;
                }
                p.details{
                    line-height:1.5;
                    
                }
            }

        }
    }   
}