.about{
    .about-banner{
        img{
            width: 100%;
            object-fit: cover;
        }
    }

    .about-content{
        margin: 100px 0px;
        .about-heading{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;
                // margin: 50px 0px;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
        }
        .content-info{
            h2{
                font-size: 2rem;
                line-height: 1.2;
                font-weight: bold;
            }
            p{
                margin-bottom: 2rem;
                font-weight: 400;
                font-size: 20px;
                line-height: 1.2;
            }
        }
        .questions{
            padding:10px 0px;
        }
        .contact-links{
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
            margin-bottom: 30px;
            border-radius: 0;
            font-weight: 700;
            font-size: 20px;
            
            a{
                background-color: #fff;
                text-decoration: none;
                padding: 10px 70px;
                margin-right: 20px;
                color: #000;
                &:first-child{
                    border-color: #45a4e2;
                    background-color: #45a4e2;
                    color: #fff;
                    &:hover{
                        background-color: #1a6292;
                    }
                }
            }
        }
    }
}