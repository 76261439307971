// Define breakpoints
$mobile-max-width: 767px;

.nav-menu{
    background: #000;
    position: fixed;
    width: 100%;
    z-index: 9;
    // opacity: 0.8;
    .top-navigation{
        // padding:0px;
        .menu-bars{
            display: none;
            margin-right: 10px;
            font-size: 30px;
            position: absolute;
            top: 10px;
            right: 20px;
            svg{
                color:#fff;
            }
        }
        
        ul.nav-links{
            margin: 0px;
            padding: 0px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            li.link:first-child{
                list-style-type: none;
                // margin-right: 40px;
                a{
                    display: block;
                    img{
                        width: 90%;
                    }
                }
            }
            li.link:not(:first-child){
                position: relative;
                list-style-type: none;
                // padding: 19px 14px;
                padding: 25px 14px;
                a{
                    color:#fff;
                    text-decoration: none;
                    font-size: 17px;
                    font-weight: 700;
                }
                &:last-child{
                    background-color: #1a91dd;
                    // margin-left:40px;
                }
                &:nth-child(2), 
                &:nth-child(3),
                &:nth-child(4){
                    .inner-links{
                        display: none;
                    }
                    &:hover{
                        .inner-links{
                            position: absolute;
                            display: block;
                            background-color: #000;
                            top: 76px;
                            ul{
                                margin: 0px;
                                padding: 0px;
                                width: 100%;
                                li{
                                    list-style-type: none;
                                    margin: 0px;
                                    padding: 0px;
                                    box-sizing: content-box;
                                    white-space: nowrap; 
                                    overflow: hidden; 
                                    text-overflow: ellipsis; 
                                    a{
                                        color:#fff;
                                        text-decoration: none;
                                        font-size: 18px;
                                        font-weight: 700;
                                        display: block;
                                        
                                        padding: 16px 30px;
                                        &:hover{
                                            transition: all 500ms;
                                            background-color: #c29f15;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav-links.for-mobile{display: none}
    }

    
}

// Media query for mobile devices
@media (max-width: $mobile-max-width) {
    // background: transparent;
    .nav-menu{
        position: unset;
        .top-navigation{
            .nav-links.for-desktop{
                display: none !important;   
            }
            .nav-links.for-mobile{
                display: none;   
                flex-direction: column;
                li.link:first-child{
                    display: none;
                }
                li.link:not(:first-child):last-child {
                    width: 100%;
                    text-align: center;
                }
            }
            .menu-bars{
                display: block;
            }
    
            ul.nav-links{
                margin: 0px;
                padding: 0px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                li.link{
                    &:active{
                        &.top-navigation .nav-links.for-mobile{
                            display: none !important;   
                        }
                    }
                }
                li.link:first-child{
                    list-style-type: none;
                    // margin-right: 40px;
                    a{
                        display: block;
                        img{
                            width: 90%;
                        }
                    }
                }
                li.link:not(:first-child){
                    &:nth-child(2), 
                    &:nth-child(3),
                    &:nth-child(4){
                        &:hover{
                            .inner-links{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}