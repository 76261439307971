.get-started{
    margin: 100px 0px;
    background-position: center;
    background-size: cover;
    
    .content-wrapper{
        min-height: 650px;
        display: flex;
        align-items: center;
        .started-heading-wrapper{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                font-weight: bold;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
        }
        .started-info-wrapper{
            ul{
                margin:0px;
                padding:0px;
                li{
                    display: flex;
                    justify-content: space-between;
                    padding-left:10px;
                    cursor: pointer;
                    align-items: center;
                    cursor: pointer;
                    font-weight: bold;
                    svg{
                        padding:20px;
                    }
                    &:hover{
                        background-color: #fff;
                        svg{
                            background-color: #1a91dd;
                            color:white;
                        }
                    }
                }
            }
        }
        .contact-us-wrapper{
            position: relative;
            // background-color: #000;
            // color:#fff;
            padding: 85px;
            min-height: 700px;
            border-radius: 15px;
            .details{
                p{

                }
                h4:first-child{
                    font-weight: bold;
                    font-size: 26px;
                }
            }
            &::after{
                background: #c29f15;
                content: "";
                width: 10px;
                height: 65%;
                position: absolute;
                top: 50%;
                right: -10px;
                transform: translate(-50%, -50%);
                border-radius: 10px;
            }
        }

    }
}