.accordion{
    .accordion-item{
        border-radius: unset !important;
        border: unset;
        background-color: unset;
        border-top: 1px solid #c29f15;
        border-bottom: 1px solid #c29f15;
        .accordion-header{
            button{
                font-size: 24px;
                font-weight: bold;
                padding: 24px 0px;
                z-index: 3;
                border-color: unset;
                outline: 0;
                box-shadow: unset;
                background-color: unset;
                font-style: italic;
            }
        }
        .accordion-body{
            padding-left:0px;
            padding-right:0px;
            p{
                font-size: 19px;
            }
        }
    }
}