.privacy-and-policy{
  .heading{
    margin-top: 100px;
    h6{
      font-weight: 900;
      font-size: 18px !important;
      padding-top: 10px;
    }
  }
  .content{

  }
} 