.testimonials{
    margin: 100px 0px;
    .content-wrapper{
        // min-height: 650px;
        display: flex;
        align-items: center;
        .started-heading-wrapper{
            h1{
                font-size: 4rem;
                margin-bottom: 4.9rem;
                &::before{
                    content: "";
                    position: relative;
                    top: 14px;
                    /* left: 7px; */
                    display: block;
                    width: 150px;
                    height: 10px;
                    margin-bottom: 1rem;
                    background-color: #c29f15;
                    transition: background-color 0.3s;
                }
            }
        }
    }
}