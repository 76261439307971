.silder-wrapper{
    .thumbs-wrapper, .carousel-status{
        display: none;
    }
    .slide{
        min-height: 450px;
        background: #000;
        
        .img{
            max-width: 475px;
            img{
                height: 475px;
                width: 100%;
                object-position: center;
                object-fit: fill;
            }
        }
        .content{
            text-align: left;
            padding: 40px;
            h2.name{
                color: #fff;
                font-weight: bold;
                margin-bottom: 1rem;
            }
            p.mc{
                margin-bottom: 3rem;
                color: #94a9cb;
            }
            p.details{
                margin-bottom: 3rem;
                color: #fff;
                font-weight: 500;
            }
            .quote-mark{
                text-align: right;
                margin-top: 5rem;
                svg{
                    font-size: 4rem;
                    color: #c29f15;
                }
            }
        }
        
    }    
}